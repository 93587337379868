<template>
<div class="course">
    <van-nav-bar
        title="课程详情"
        fixed
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
    >
        <!-- <template #right>
            <van-icon name="share" size="18" />
        </template> -->
    </van-nav-bar>
    
    <van-swipe class="van-swipe" :autoplay="3000">
        <van-swipe-item class="van-swipe-item" v-for="(image, index) in [courseInfo.image]" :key="index">
            <!-- <img v-lazy="courseImage(image)" /> -->
            <img :src="courseImage(image)" />
        </van-swipe-item>
    </van-swipe>
    
    <div class="title-price-box">
        <div class="title">
            {{courseInfo.title}}
        </div>
        <div class="price" v-if="courseInfo.price>0">
            <span class="small">¥</span> <span>{{courseInfo.price}}</span>
        </div>
        <div class="price" v-else>
            <span class="free">免费</span>
        </div>
    </div>
    <div class="content">
        <div class="title">
            课程介绍
        </div>
        <div class="intro-html" v-html="formatHtml(courseInfo.content)">
        </div>
    </div>
    <div class="agree" v-if="is4990">
        <van-checkbox icon-size="16px" class="agree-checkbox" v-model="agree" />
        <div>同意签署<span class="color-blue" @click="$router.push({name:'agree',params:{t:'mcn'}})">《MCN培训协议》</span>和<span class="color-blue" @click="$router.push({name:'agree',params:{t:'photo4990'}})">《主播拍摄知识产权归属及肖像权使用合同》</span></div>
    </div>
    <div class="agree" v-if="is1990">
        <van-checkbox icon-size="16px" class="agree-checkbox" v-model="agree" />
        <div>同意签署<span class="color-blue" @click="$router.push({name:'agree',params:{t:'photo1990'}})">《主播拍摄知识产权归属及肖像权使用合同》</span></div>
    </div>
    <div class="btm">
        <div class="favorite" @click="like=!like">
            <van-icon v-if="like" name="star" size="24" />
            <van-icon v-else name="star-o" size="24" />
            <span class="text">收藏</span>
        </div>
        <div class="btn">
            <van-button block :loading="loading" type="info" @click="goCart">开始学习</van-button>
        </div>
    </div>

    <van-share-sheet
    v-model="showShare"
    title="立即分享给好友"
    :options="options"
    @select="onSelect"
    />

</div>
  
</template>
<script>
import cfg from "../config.js";
import { setLocalStorage } from '../utils/funs/storage';
import { Toast } from 'vant';
export default {
  name: "Course",
  data() {
    return {
        loading: false,
        agree: false,
        ctype: "",
        cid: "",
        like: false,
        courseInfo: {},
        showShare: false,
        options: [
            { name: '微信', icon: 'wechat' },
            { name: '微博', icon: 'weibo' },
            { name: '复制链接', icon: 'link' },
            { name: '分享海报', icon: 'poster' },
            { name: '二维码', icon: 'qrcode' },
        ],
    };
  },
  components:{
  },
  mounted() {
    this.ctype = this.$route.params.type;
    this.cid = this.$route.params.id;
    this.getCourse();
  },
  computed:{
    is4990(){
        return this.ctype == 'media' && this.cid ==2;
    },
    is1990(){
        return this.ctype == 'media' && this.cid ==3;
    }
  },
  methods: {
    
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.showShare = true;
    },
    onSelect(option) {
      Toast(option.name);
      this.showShare = false;
    },
    courseImage(url){
        if(url){
            return /^http/.test(url) ? url : cfg._ASSETS_HOST + url;
        }else{
            return require("../assets/logo.png");
        }
    },
    formatHtml(_html){
        return _html?_html.replaceAll('<img', '<img style="width:100%;vertical-align: top;margin:0px auto;"'):_html;
    },
    getCourse(){
        let _this = this;
        this.$http.call(this.$http.api.course, {
            data: {
                type: _this.ctype,
                id: _this.cid
            }
        }).then(res=>{
            let resp = res.data;
            // console.log(resp);
            if(resp.code=='0'){
                this.courseInfo = resp.data;
            }else{
                Toast(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    goCart(){
        if((this.is1990 || this.is4990) && !this.agree){
            Toast("购买课程需同意签署相关协议");
            return;
        }
        let _data = {
            title: this.courseInfo.title,
            price: this.courseInfo.price,
            image: this.courseImage(this.courseInfo.image),
            ctype: this.ctype,
            cid: this.cid
        };
        setLocalStorage('cartCourse', _data);
        this.$router.push({name:'cart'});
    }
  },
};
</script>
<style lang="less" scoped>
.course {
    padding: 50px 0;
    .van-swipe-item {
        color: #fff;
        font-size: 20px;
        height: 200px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .title-price-box {
        padding: 15px;
        margin-bottom: 15px;
        text-align: left;
        background: #fff;
        font-weight: bold;
        .price {
            padding: 15px 0;
            color: #eb645e;
            font-size: 20px;
            .small {
                font-size: 16px;
            }
        }
    }
    .agree {
        padding: 15px 15px 50px;
        font-size: 13px;
        display: flex;
        height: 20px; 
        line-height: 20px;
        .agree-checkbox {
            padding-right: 10px;
        }
    }
    .content {
        background: #fff;
        padding: 15px;
        .title {
            font-weight: bold;
            text-align: left;
            padding-bottom: 15px;
        }
    }

    .btm {
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #fff;
        padding: 10px 0;
        display: flex;
        .favorite {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            color: #eb645e;
            .text {
                font-size: 12px;
            }
        }
        .btn {
            flex: 1;
            padding-right: 15px;
        }
    }
}
</style>
<style>
.intro-html img {
    width: 100% !important;
    max-width: 100% !important;
}
.intro-html p{
    padding: 0 !important;
    margin: 0 !important;
}
</style>